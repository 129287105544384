<template>
  <div class="login">
    <el-container>
      <el-header>
        <div style="text-align: center;font-size: 30px;font-weight: bolder">
          下载中心
        </div>
      </el-header>
      <el-main>
        <div style="text-align: center;font-size: 30px;font-weight: bolder">
          <el-button type="primary" style="margin-right: 20px;height: 100px;width: 100%" @click="downloadVisible=true">物业用户</el-button>
        </div>
        <div style="text-align: center;font-size: 30px;font-weight: bolder">
          <el-button type="primary" style="margin-right: 20px;margin-top:50px;height: 100px;width: 100%" @click="downloadVisibleApp=true">维保人员</el-button>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="downloadVisible"
      title="下载电梯智慧物联"
      width="100%"
      @close="dialogDownloadClose">
      <div v-if="name !=='Iphone'&& name!=='Mac'">
        <a style="font-size: 22px;font-weight: bold" href="https://gr-iot.cn/物业端.apk">点击本段文字下载app或扫码下载</a>
        <vueQr text="https://gr-iot.cn/物业端.apk"></vueQr>
      </div>
      <div v-else>
        <a style="font-size: 22px;font-weight: bold" href="https://testflight.apple.com/join/aW8qMx1f">点击安装</a>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="downloadVisibleApp"
      title="下载电梯智慧物联管理平台"
      width="100%"
      @close="dialogDownloadClose">
      <div v-if="name !=='Iphone'&& name!=='Mac'">
        <a style="font-size: 22px;font-weight: bold" href="https://gr-iot.cn/维保端.apk">点击本段文字下载app或扫码下载</a>
        <vueQr text="https://gr-iot.cn/维保端.apk"></vueQr>
      </div>
      <div v-else>
        <a style="font-size: 22px;font-weight: bold" href="https://testflight.apple.com/join/CWUl6mHr">点击安装</a>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import vueQr from "vue-qr";

  export default {
    components:{vueQr},

    data() {
      return {
        downloadVisible: false,
        downloadVisibleApp: false,
        name: "",
      };
    },
    created() {
      console.log(this.getOsInfo(),166);
    },
    methods: {
      dialogClose() {
        this.downloadVisible =false;
      },
      dialogDownloadClose() {
        // this.$refs.findPasswordForm.resetFields();
        // this.sendMsgBtnLoading = false;
        this.downloadVisibleApp =false;
      },
      getOsInfo() {
        var userAgent = navigator.userAgent.toLowerCase();
        let name = "Unknown";
        let version = "Unknown";
        if (userAgent.indexOf("win") > -1) {
          name = "Windows";
          if (userAgent.indexOf("windows nt 5.0") > -1) {
            version = "Windows 2000";
          } else if (
            userAgent.indexOf("windows nt 5.1") > -1 ||
            userAgent.indexOf("windows nt 5.2") > -1
          ) {
            version = "Windows XP";
          } else if (userAgent.indexOf("windows nt 6.0") > -1) {
            version = "Windows Vista";
          } else if (
            userAgent.indexOf("windows nt 6.1") > -1 ||
            userAgent.indexOf("windows 7") > -1
          ) {
            version = "Windows 7";
          } else if (
            userAgent.indexOf("windows nt 6.2") > -1 ||
            userAgent.indexOf("windows 8") > -1
          ) {
            version = "Windows 8";
          } else if (userAgent.indexOf("windows nt 6.3") > -1) {
            version = "Windows 8.1";
          } else if (
            userAgent.indexOf("windows nt 6.2") > -1 ||
            userAgent.indexOf("windows nt 10.0") > -1
          ) {
            version = "Windows 10";
          } else {
            version = "Unknown";
          }
        } else if (userAgent.indexOf("iphone") > -1) {
          name = "Iphone";
        } else if (userAgent.indexOf("mac") > -1) {
          name = "Mac";
        } else if (
          userAgent.indexOf("x11") > -1 ||
          userAgent.indexOf("unix") > -1 ||
          userAgent.indexOf("sunname") > -1 ||
          userAgent.indexOf("bsd") > -1
        ) {
          name = "Unix";
        } else if (userAgent.indexOf("linux") > -1) {
          if (userAgent.indexOf("android") > -1) {
            name = "Android";
          } else {
            name = "Linux";
          }
        } else {
          name = "Unknown";
        }
        this.name = name;
        return {name, version};
      },


    },
  };
</script>

<style lang="scss" scoped>
</style>
